import { createTheme } from '@mui/material'
import { ThemeProvider } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'
import store from '../../redux/store'
import inMemoryJwtService from '../../services/inMemoryJwtService'
import AboutUs from '../About/AboutUs'
import Calculate from '../Calculate'
import Footer from '../Footer'
import Home from '../Home/Home'
import LandingPage from '../Landing/LandingPage'
import FormDialog from '../Login/FormDialog'
import NavBar from '../NavBar'

const Public = () => {
    const theme = createTheme()

    return (

        <>
            {console.log("token", inMemoryJwtService.getToken())}
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <NavBar />
                </ThemeProvider>
                <Routes>
                    <Route path={`/`} element={<Home />} />
                    <Route path={`/about`} element={<AboutUs />} />
                    <Route path={`/calculate`} element={<Calculate />} />
                    <Route
                        path={`/thankyou`}
                        element={
                            <ThemeProvider theme={theme}>
                                <LandingPage />
                            </ThemeProvider>
                        }
                    />
                    <Route path={`/signup`} element={<FormDialog />} />
                </Routes>
                <ThemeProvider theme={theme}>
                    <Footer />
                </ThemeProvider>
            </Provider>
        </>
    )
}

export default Public