import Client from "../client/Client";
import Public from "./public/Public";
import crypto from 'crypto-js'
import { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function PrivateRoute() {
    const [isAuth, setIsAuth] = useState(localStorage.getItem("authUser"))
    

    return isAuth ? <Outlet /> : <Navigate to="/"  />;
}

export default PrivateRoute;